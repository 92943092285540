$(function() {
  // スムーススクロールの実装
  $('a[href^="#"]').click(function(){
    let speed = 500;
    let href= $(this).attr("href");
    let target = $(href == "#" || href == "" ? 'html' : href);
    let position = target.offset().top - $("#header").height();
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });

  // Slickスライダーの実装
  $(".mv__slick-slider").slick({
    autoplay: true,
    autoplaySpeed: 5000,
    fade: false,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

  // スクロールにより可視領域に入った場合の処理
  $(".js-fadeUp").on("inview", function () {
    $(this).addClass("is-inview");
  });
});

